<!--
 * @Description: 
 * @Author: huacong
 * @Date: 2021-09-01 16:39:00
 * @LastEditTime: 2021-09-02 17:19:43
 * @LastEditors: huacong
-->
<!-- 企业招兼职 -->
<template>
  <div class="contentBox">
    <section>
      <!-- 筛选条件 -->
      <div class="conditionBox">
        <el-form class="conditionForm" label-width="65px">
          <el-form-item label="省份：" class="proviceBox">
            <el-button
              type="text"
              v-small-repeat-click
              @click="clearProvice($event)"
              size="mini"
              class="selbtn selproviceBtn"
            >
              不限
            </el-button>
            <el-button
              type="text"
              v-small-repeat-click
              @click="selProvice($event, item.value, item.label)"
              v-for="item in proviceData"
              :key="item.value"
              size="mini"
              class="selbtn selproviceBtn"
            >
              {{ item.label }}
            </el-button>
            <el-button
              type="text"
              v-if="morepro"
              @click="moreProbtn"
              class="foldbtn"
              >更多</el-button
            >
            <el-button
              type="text"
              v-if="packpro"
              @click="packProbtn"
              class="foldbtn"
              >收起</el-button
            >
          </el-form-item>
          <el-form-item label="城市：">
            <el-button
              type="text"
              v-small-repeat-click
              @click="clearCity($event)"
              size="mini"
              class="selbtn selcityBtn"
            >
              不限
            </el-button>
            <el-button
              type="text"
              v-small-repeat-click
              @click="selCity($event, item.label)"
              v-for="item in cityData"
              :key="item.value"
              size="mini"
              class="selbtn selcityBtn"
            >
              {{ item.label }}
            </el-button>
          </el-form-item>
          <div class="text-center">
            <el-button type="text" style="color:#FF0000" @click="clearSearch">
              清除筛选条件
            </el-button>
          </div>
        </el-form>
      </div>
      <!-- 数据展示区域--表格 -->
      <div class="partTimeBox">
        <el-row :gutter="24">
          <el-col :span="24">
            <el-table
              :data="partData"
              :cell-style="{ padding: 15 + 'px' }"
              :header-cell-style="{ padding: 15 + 'px' }"
              class="partTimeTable"
            >
              <el-table-column label="企业名称" prop="com_name">
              </el-table-column>
              <el-table-column
                label="联系人"
                prop="com_contact"
                width="100px"
              ></el-table-column>
              <el-table-column
                label="联系电话"
                prop="contact_phone"
                width="150px"
              ></el-table-column>
              <el-table-column label="地区" width="180px">
                <template slot-scope="scope">
                  <div>{{ scope.row.province }}-{{ scope.row.city }}</div>
                </template>
              </el-table-column>
              <el-table-column
                label="企业需求"
                prop="com_need"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                label="发布时间"
                prop="create_time"
                width="125px"
              ></el-table-column>
              <div slot="empty" class="empty">
                <div>
                  <img :src="noDate" />
                </div>
                <span>暂无数据</span>
              </div>
            </el-table>
            <div class="pageBox">
              <el-pagination
                background
                @size-change="sizeChange1"
                @current-change="currentChange1"
                :current-page="currentPage"
                :page-sizes="[10, 20, 30, 40, 50]"
                :page-size="pagesize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableTotal"
              >
              </el-pagination>
            </div>
          </el-col>
        </el-row>
      </div>
    </section>
  </div>
</template>
<script>
import $ from "jquery";
import { provinceAndCityData } from "element-china-area-data";
export default {
  name: "aptitudeIndex",
  components: {},
  data() {
    return {
      searchIcon: require("@/assets/icon/search-icon.png"),
      noDate: require("@/assets/img/noDate.png"),
      searchCer: "",
      cerList: [],
      majorList: [],
      searchInfo: {},
      proviceData: provinceAndCityData,
      cityData: [],
      partData: [],
      morepro: true,
      packpro: false,
      currentPage: 1,
      pagesize: 10,
      tableTotal: 0,
    };
  },
  methods: {
    // 获取证书列表
    getCerList() {
      this.$http.post("index/certificate/certificate").then((res) => {
        if (res.data.resultCode === 1) {
          this.cerList = res.data.resultData;
          let parmes = this.$route.params;
          if (parmes.cer != undefined) {
            this.searchInfo.certificate = parmes.cer;
            this.cerList.forEach((element) => {
              if (parmes.cer == element.certificate) {
                this.majorList = element.child;
              }
            });
          } else {
            this.majorList = res.data.resultData[0].child;
          }
        }
      });
    },
    // 选择证书分类 event:当前按钮  e:该证书在证书列表的id
    selCer(event, e, cer) {
      $(".selcerBtn").removeClass("active");
      var el = event.currentTarget;
      el.classList.add("active");

      this.cerList.forEach((element) => {
        if (e == element.id) {
          this.majorList = element.child;
        }
      });
      this.searchInfo.certificate = cer;
      this.searchInfo.major = "";
      $(".selmajorBtn").removeClass("active");
      this.getParttimeList();
    },
    clearCer(event) {
      $(".selcerBtn").removeClass("active");
      var el = event.currentTarget;
      el.classList.add("active");
      this.searchInfo.certificate = "";
      this.searchInfo.major = "";
      this.getParttimeList();
    },
    // 选择专业
    selmajor(event, major) {
      $(".selmajorBtn").removeClass("active");
      $(".selcerBtn").removeClass("active");
      var el = event.currentTarget;
      el.classList.add("active");
      this.searchInfo.category = major;
      this.getParttimeList();
    },
    clearmajor(event) {
      $(".selmajorBtn").removeClass("active");
      var el = event.currentTarget;
      el.classList.add("active");
      this.searchInfo.category = "";
      this.getParttimeList();
    },
    /**
     * @description: 获取省份
     * @param  {event: 当前按钮}
     * @param  {e: 当前选项的值}
     * @param  {provice: 当前选的省份}
     * @return {*}
     */
    selProvice(event, e, provice) {
      $(".selproviceBtn").removeClass("active");
      var el = event.currentTarget;
      el.classList.add("active");

      this.proviceData.forEach((element) => {
        if (e == element.value) {
          this.cityData = element.children;
        }
      });
      this.searchInfo.province = provice;
      this.searchInfo.city = "";
      $(".selcityBtn").removeClass("active");
      this.getParttimeList();
    },
    clearProvice(event) {
      $(".selproviceBtn").removeClass("active");
      $(".selcityBtn").removeClass("active");
      var el = event.currentTarget;
      el.classList.add("active");
      this.searchInfo.province = "";
      this.searchInfo.city = "";
      this.getParttimeList();
    },
    // 选择城市city
    selCity(event, city) {
      $(".selcityBtn").removeClass("active");
      var el = event.currentTarget;
      el.classList.add("active");
      this.searchInfo.city = city;
      this.getParttimeList();
    },
    clearCity(event) {
      $(".selcityBtn").removeClass("active");
      var el = event.currentTarget;
      el.classList.add("active");
      this.searchInfo.city = "";
      this.getParttimeList();
    },
    // 获取资质列表
    getParttimeList(page, limit) {
      if (page == undefined || limit == undefined) {
        this.searchInfo.page = 1;
        this.searchInfo.limit = 10;
      } else {
        this.searchInfo.page = this.currentPage;
        this.searchInfo.limit = this.pagesize;
      }
      this.$http
        .post("index/company/getparlist", this.searchInfo)
        .then((res) => {
          if (res.data.resultCode === 1) {
            this.partData = res.data.resultData.list;
            this.tableTotal = parseInt(res.data.resultData.count);
          }
        });
    },
    sizeChange1(e) {
      this.pagesize = e;
      this.currentPage = 1;
      this.getParttimeList(this.currentPage, this.pagesize);
    },
    currentChange1(e) {
      this.currentPage = e;
      this.getParttimeList(e, this.pagesize);
    },
    // 清除筛选
    clearSearch() {
      $(".selcerBtn").removeClass("active");
      $(".selmajorBtn").removeClass("active");
      $(".selproviceBtn").removeClass("active");
      $(".selcityBtn").removeClass("active");
      this.searchInfo = {};
      this.getParttimeList();
    },
    // 展示更多省份
    moreProbtn() {
      this.morepro = false;
      this.packpro = true;
      $(".proviceBox").css({ height: "auto" });
    },
    // 折叠省份
    packProbtn() {
      this.morepro = true;
      this.packpro = false;
      $(".proviceBox").css({ height: "40px" });
    },
    // 搜索
    postSearch() {
      this.searchInfo.category = this.searchCer;
      this.getParttimeList();
    },
    goDetail(id) {
      let routeUrl = this.$router.resolve({
        name: "aptitudeDetail",
        query: { id: id },
      });
      window.open(routeUrl.href, "_blank");
    },
  },
  mounted() {
    this.getCerList();
    this.getParttimeList();
  },
};
</script>
<style scoped>
.searchBox {
  margin: 0px 100px;
}
.searchInput {
  /* width: calc(100% - 136px); */
}
/deep/.searchBox .el-input__inner {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
/deep/.searchBox .el-input-group__append {
  background-color: #0070fa;
  border-color: #0070fa;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  color: #fff;
  cursor: pointer;
}
.el-input-group__append img {
  width: 20px;
}
.conditionBox {
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 24px;
}
/deep/.conditionForm .el-form-item {
  margin-bottom: 0;
  padding: 7px 20px;
  border-bottom: 1px solid #e8e8e8;
}
/deep/.conditionForm .el-form-item__label {
  color: #333;
  font-size: 17px;
  font-weight: bold;
}
.proviceBox {
  height: 40px;
  overflow: hidden;
  position: relative;
}
.foldbtn {
  position: absolute;
  right: -10px;
  top: 0px;
}
.conditionForm .selbtn {
  color: #333;
  font-size: 13px;
}
.el-button--text.color999 {
  color: #999;
}
.selcerBtn,
.selmajorBtn,
.selcityBtn,
.selproviceBtn {
  padding-left: 7px;
  padding-right: 7px;
}
.selcerBtn.active,
.selmajorBtn.active,
.selcityBtn.active,
.selproviceBtn.active {
  background: #2573f1;
  border-radius: 4px;
  color: #fff;
}
/* /deep/ .partTimeTable .el-table__header-wrapper {
  height: 0;
} */
.cername span {
  cursor: pointer;
  font-size: 17px;
  font-weight: bold;
  color: #333;
}
.cerstate {
  padding-top: 2px;
}
.cerstate span {
  color: #999;
}
.cerstate span:not(:last-child) {
  margin-right: 20px;
}
.cerstate .cerPay {
  font-size: 17px;
  font-weight: bold;
  color: #ff0000;
}
.avatarBox {
  line-height: 12px;
}
.avatarBox > span:first-child {
  vertical-align: middle;
  margin-right: 12px;
}
.tr:hover {
  box-shadow: 0px 5px 14px 6px rgba(173, 193, 213, 0.5);
}
</style>
