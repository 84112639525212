<!--
 * @Description: 企业列表
 * @Author: huacong
 * @Date: 2021-04-27 16:25:00
 * @LastEditTime: 2021-09-02 10:39:09
 * @LastEditors: huacong
-->
<template>
  <div class="contentBox">
    <section>
      <div style="margin-top: 20px;">
        <el-radio-group
          v-model="workType"
          size="small"
          @change="changeWorkType"
        >
          <el-radio-button label="企业兼职"></el-radio-button>
          <el-radio-button label="企业全职"></el-radio-button>
          <!-- <el-radio-button label="猎头荐才"></el-radio-button> -->
        </el-radio-group>
      </div>
    </section>
    <!-- 企业兼职 -->
    <partCom v-show="compartShow"></partCom>
    <!-- 企业全职 -->
    <section v-show="comfullShow" style="padding-top: 24px;">
      <div class="conditionBox">
        <el-form class="conditionForm" label-width="97px">
          <el-form-item label="所在省份：" class="proviceBox">
            <el-button
              type="text"
              v-small-repeat-click
              @click="clearProvice($event)"
              size="mini"
              class="selbtn selproviceBtn color999"
            >
              不限
            </el-button>
            <el-button
              type="text"
              v-small-repeat-click
              @click="selProvice($event, item.value, item.label)"
              v-for="item in proviceData"
              :key="item.value"
              size="mini"
              class="selbtn selproviceBtn"
            >
              {{ item.label }}
            </el-button>
            <el-button
              type="text"
              v-if="morepro"
              @click="moreProbtn"
              class="foldbtn"
              >更多</el-button
            >
            <el-button
              type="text"
              v-if="packpro"
              @click="packProbtn"
              class="foldbtn"
              >收起</el-button
            >
          </el-form-item>
          <el-form-item label="所在城市：">
            <el-button
              type="text"
              v-small-repeat-click
              @click="clearCity($event)"
              size="mini"
              class="selbtn selcityBtn color999"
            >
              不限
            </el-button>
            <el-button
              type="text"
              v-small-repeat-click
              @click="selCity($event, item.label)"
              v-for="item in cityData"
              :key="item.value"
              size="mini"
              class="selbtn selcityBtn"
            >
              {{ item.label }}
            </el-button>
          </el-form-item>
          <el-form-item label="企业性质：">
            <el-button
              type="text"
              v-small-repeat-click
              @click="clearxingzhi($event)"
              size="mini"
              class="selbtn selxingzhiBtn color999"
            >
              不限
            </el-button>
            <el-button
              type="text"
              v-small-repeat-click
              @click="selXingzhi($event, item.value, item.label)"
              v-for="item in xingzhiData"
              :key="item.value"
              size="mini"
              class="selbtn selxingzhiBtn"
            >
              {{ item.label }}
            </el-button>
          </el-form-item>
          <!-- <el-form-item label="企业种类：">
            <el-button
              type="text"
              v-small-repeat-click
              @click="clearType($event)"
              size="mini"
              class="selbtn seltypeBtn color999"
            >
              不限
            </el-button>
            <el-button
              type="text"
              v-small-repeat-click
              @click="selType($event, item.label)"
              v-for="item in typeData"
              :key="item.value"
              size="mini"
              class="selbtn seltypeBtn"
            >
              {{ item.label }}
            </el-button>
          </el-form-item> -->
          <el-form-item label="企业规模：">
            <el-button
              type="text"
              v-small-repeat-click
              @click="clearSize($event)"
              size="mini"
              class="selbtn selsizeBtn color999"
            >
              不限
            </el-button>
            <el-button
              type="text"
              v-small-repeat-click
              @click="selSize($event, item.label)"
              v-for="item in sizeData"
              :key="item.value"
              size="mini"
              class="selbtn selsizeBtn"
            >
              {{ item.label }}
            </el-button>
          </el-form-item>
          <div class="text-center">
            <el-button type="text" style="color:#FF0000">
              清除筛选条件
            </el-button>
          </div>
        </el-form>
      </div>
      <!-- 数据展示区域--表格 -->
      <div class="partTimeBox">
        <el-row :gutter="24">
          <el-col :span="6" v-for="item in partData" :key="item.id">
            <div class="comItem" @click="goComDetail(item)">
              <div class="text-center comImg">
                <img :src="item.com_url" alt="" />
              </div>
              <p class="text-center comName">
                {{ item.com_external_name }}
                <img :src="vipIcon" alt="" />
              </p>
              <p class="text-center comAdress">
                <i class="el-icon-location"></i>
                {{ item.com_province }}-{{ item.com_city }}
              </p>
              <div style="padding:20px">
                <el-row class="parseBox">
                  <el-col :span="24">
                    <p class="parseName">在招职位</p>
                    <p class="parseNum">
                      {{
                        item.full_job_count +
                          item.par_job_count +
                          item.resume_count
                      }}
                    </p>
                  </el-col>
                  <!-- <el-col :span="12">
                    <p>简历反馈率</p>
                    <p>
                      
                    </p>
                  </el-col> -->
                </el-row>
              </div>
            </div>
          </el-col>
        </el-row>
        <div class="pageBox">
          <el-pagination
            background
            @size-change="sizeChange1"
            @current-change="currentChange1"
            :current-page="currentPage"
            :page-sizes="[12, 24, 48]"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableTotal"
          >
          </el-pagination>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import $ from "jquery";
import { provinceAndCityData } from "element-china-area-data"; // CodeToText
import partCom from "@/view/partCom/partCom";
export default {
  name: "comList",
  components: {
    partCom,
  },
  data() {
    return {
      proviceData: provinceAndCityData,
      vipIcon: require("@/assets/icon/vip.png"),
      searchInfo: {},
      cityData: [],
      partData: [],
      morepro: true,
      packpro: false,
      currentPage: 1,
      pagesize: 10,
      tableTotal: 0,
      xingzhiData: [
        { label: "外商独资", value: 1 },
        { label: "中外合资", value: 2 },
        { label: "国有企业 ", value: 3 },
        { label: "外企代表处 ", value: 4 },
        { label: "政府机关", value: 5 },
        { label: "事业单位", value: 6 },
        { label: "股份制企业", value: 7 },
        { label: "私营/民营企业", value: 8 },
        { label: "混合所有制", value: 9 },
        { label: "500强企业", value: 10 },
        { label: "其他", value: 11 },
      ],
      typeData: [
        { label: "企业", value: 2 },
        { label: "中介", value: 3 },
      ],
      sizeData: [
        { label: "1-49人", value: 1 },
        { label: "50-99人", value: 2 },
        { label: "100-499人", value: 3 },
        { label: "500-999人", value: 4 },
        { label: "1000人以上", value: 5 },
      ],
      workType: "企业兼职",
      compartShow: true,
      comfullShow: false,
    };
  },
  methods: {
    /**
     * @description: 获取省份
     * @param  {event: 当前按钮}
     * @param  {e: 当前选项的值}
     * @param  {provice: 当前选的省份}
     * @return {*}
     */
    selProvice(event, e, provice) {
      $(".selproviceBtn").removeClass("active");
      var el = event.currentTarget;
      el.classList.add("active");

      this.proviceData.forEach((element) => {
        if (e == element.value) {
          this.cityData = element.children;
        }
      });
      this.searchInfo.com_province = provice;
      this.searchInfo.com_city = "";
      $(".selcityBtn").removeClass("active");
      this.getComList();
    },
    clearProvice(event) {
      $(".selproviceBtn").removeClass("active");
      $(".selcityBtn").removeClass("active");
      var el = event.currentTarget;
      el.classList.add("active");
      this.searchInfo.com_province = "";
      this.searchInfo.com_city = "";
      this.getComList();
    },
    // 选择城市city
    selCity(event, city) {
      $(".selcityBtn").removeClass("active");
      var el = event.currentTarget;
      el.classList.add("active");
      this.searchInfo.com_city = city;
      this.getComList();
    },
    clearCity(event) {
      $(".selcityBtn").removeClass("active");
      var el = event.currentTarget;
      el.classList.add("active");
      this.searchInfo.com_city = "";
      this.getComList();
    },
    // 选择性质
    selXingzhi(event, nature) {
      $(".selxingzhiBtn").removeClass("active");
      var el = event.currentTarget;
      el.classList.add("active");
      this.searchInfo.com_nature = nature;
      this.getComList();
    },
    clearxingzhi(event) {
      $(".selxingzhiBtn").removeClass("active");
      var el = event.currentTarget;
      el.classList.add("active");
      this.searchInfo.com_nature = "";
      this.getComList();
    },
    // 选择企业类型
    selType(event, type) {
      $(".seltypeBtn").removeClass("active");
      var el = event.currentTarget;
      el.classList.add("active");
      this.searchInfo.type = type;
      this.getComList();
    },
    clearType(event) {
      $(".seltypeBtn").removeClass("active");
      var el = event.currentTarget;
      el.classList.add("active");
      this.searchInfo.type = "";
      this.getComList();
    },
    // 选择规模
    selSize(event, size) {
      $(".selsizeBtn").removeClass("active");
      var el = event.currentTarget;
      el.classList.add("active");
      this.searchInfo.com_size = size;
      this.getComList();
    },
    clearSize(event) {
      $(".selsizeBtn").removeClass("active");
      var el = event.currentTarget;
      el.classList.add("active");
      this.searchInfo.com_size = "";
      this.getComList();
    },
    // 获取兼职列表
    getComList(page, limit) {
      if (page == undefined || limit == undefined) {
        this.searchInfo.page = 1;
        this.searchInfo.limit = 10;
      } else {
        this.searchInfo.page = this.currentPage;
        this.searchInfo.limit = this.pagesize;
      }
      this.$http.post("index/company/getlist", this.searchInfo).then((res) => {
        if (res.data.resultCode === 1) {
          this.partData = res.data.resultData.list;
          this.tableTotal = parseInt(res.data.resultData.count);
        }
      });
    },
    sizeChange1(e) {
      this.pagesize = e;
      this.currentPage = 1;
      this.getComList(this.currentPage, this.pagesize);
    },
    currentChange1(e) {
      this.currentPage = e;
      this.getComList(e, this.pagesize);
    },
    // 展示更多省份
    moreProbtn() {
      this.morepro = false;
      this.packpro = true;
      $(".proviceBox").css({ height: "auto" });
    },
    // 折叠省份
    packProbtn() {
      this.morepro = true;
      this.packpro = false;
      $(".proviceBox").css({ height: "40px" });
    },
    // 跳转公司详情
    goComDetail(item) {
      let routeUrl = this.$router.resolve({
        name: "comDetail",
        query: { comid: item.id },
      });
      window.open(routeUrl.href, "_blank");
    },
    //
    changeWorkType(e) {
      if (e == "企业兼职") {
        this.comfullShow = false;
        this.compartShow = true;
      }
      if (e == "企业全职") {
        this.compartShow = false;
        this.comfullShow = true;
      }
    },
    setSwitch() {
      let parmes = this.$route.params;
      if (parmes.comtype) {
        this.compartShow = false;
        this.comfullShow = true;
        this.workType = "企业全职";
      }
    },
  },
  mounted() {
    this.getComList();
    this.setSwitch();
  },
};
</script>
<style scoped>
.searchBox {
  margin: 0px 100px;
}
.searchInput {
  width: calc(100% - 136px);
}
/deep/.searchBox .el-input__inner {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
/deep/.searchBox .el-input-group__append {
  background-color: #0070fa;
  border-color: #0070fa;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  color: #fff;
  cursor: pointer;
}
.el-input-group__append img {
  width: 20px;
}
.conditionBox {
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 24px;
}
/deep/.conditionForm .el-form-item {
  margin-bottom: 0;
  padding: 7px 20px;
  border-bottom: 1px solid #e8e8e8;
}
/deep/.conditionForm .el-form-item__label {
  color: #333;
  font-size: 17px;
  font-weight: bold;
}
.proviceBox {
  height: 40px;
  overflow: hidden;
  position: relative;
}
.foldbtn {
  position: absolute;
  right: -10px;
  top: 0px;
}
.conditionForm .selbtn {
  color: #333;
  font-size: 13px;
}
.el-button--text.color999 {
  color: #999;
}
.selcerBtn,
.selmajorBtn,
.selcityBtn,
.selproviceBtn,
.selxingzhiBtn,
.seltypeBtn,
.selsizeBtn {
  padding-left: 7px;
  padding-right: 7px;
}
.selcerBtn.active,
.selmajorBtn.active,
.selcityBtn.active,
.selproviceBtn.active,
.selxingzhiBtn.active,
.seltypeBtn.active,
.selsizeBtn.active {
  background: #2573f1;
  border-radius: 4px;
  color: #fff;
}
/deep/ .partTimeTable .el-table__header-wrapper {
  height: 0;
}
.comItem {
  background: #ffffff;
  border-radius: 4px;
}
.comItem:hvoer {
  box-shadow: 0px 5px 14px 6px rgba(173, 193, 213, 0.5);
}
.comImg {
  padding: 20px;
}
.comImg img {
  height: 90px;
}
.comName {
  font-size: 17px;
  font-weight: bold;
  color: #333;
  padding-top: 6px;
}
.comAdress i {
  margin-right: 3px;
}
.comAdress {
  color: #333;
  padding-top: 6px;
}
.parseBox {
  border-top: 1px solid #e8e8e8;
}
.parseName {
  color: #999;
  text-align: center;
  padding: 20px 0 10px;
}
.parseNum {
  color: #ff0000;
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  padding: 10px 0 0;
}
</style>
